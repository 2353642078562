import React, { useState } from "react";
import { DriverStandings } from "./DriverStandings";
import { ConstructorStandings } from "./ConstructorStandings";
import { YearPicker } from "../Shared/YearPicker";

export const Standings = () => {
    enum Tab {
        Driver = 1,
        Constructor = 2,
    }

    const [activeTab, setActiveTab] = useState<Tab>(Tab.Driver);
    const [year, setYear] = useState<number>(new Date().getFullYear());

    return (
        <div className="flex flex-col sm:w-3/4 2xl:w-3/5">
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Ranking
            </h1>

            <hr className="border-gray-200 border my-4" />

            <YearPicker onChange={(year) => setYear(year)}></YearPicker>
            
            <div className="w-full mb-2 mt-7">
                <div className="tabs tabs-boxed bg-gray-200 w-fit">
                    <button
                        className={`tab ${
                            activeTab === Tab.Driver ? "tab-active !bg-gray-500" : ""
                        }`}
                        onClick={() => setActiveTab(Tab.Driver)}
                    >
                        Rijders
                    </button>
                    <button
                        className={`tab ${
                            activeTab === Tab.Constructor ? "tab-active !bg-gray-500" : ""
                        }`}
                        onClick={() => setActiveTab(Tab.Constructor)}
                    >
                        Constructeurs
                    </button>
                </div>
            </div>

            <hr className="border-gray-200 border my-4" />

            <div
                className={`${activeTab === Tab.Driver ? "display" : "hidden"}`}
            >
                <DriverStandings year={year} />
            </div>

            <div
                className={`${
                    activeTab === Tab.Constructor ? "display" : "hidden"
                }`}
            >
                <ConstructorStandings year={year} />
            </div>
        </div>
    );
};
