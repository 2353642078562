import { useEffect, useState } from "react";
import { Countdown } from "./Shared/Countdown";
import { RaceInfo } from "./RaceSchema/RaceInfo";
import { QualifyResultModal } from "./RaceResults/QualifyResultModal";
import { Race } from "../models";
import { Loading } from "./Shared/Loading";

export const Home = () => {
    const [nextRace, setNextRace] = useState<Race | undefined>(undefined);
    const [showQualifyModal, setShowQualifyModal] = useState<boolean>(false);

    useEffect(() => {
        fetch("api/race/next")
            .then((response) => response.json())
            .then((data: Race) => {
                setNextRace(data);
            });
    }, []);

    function getYear(dateString: string) {
        const date = new Date(dateString);
        return date.getFullYear();
    }

    function qualifyingResults(nextRace: Race) {
        const qualifyDate = new Date(nextRace.sessions.qualifying);
        const currentDateTime = new Date();

        if (qualifyDate < currentDateTime) {
        return (
            <div className="w-60 my-8 text-center">
                <button
                    className="btn btn-neutral text-gray-300"
                    onClick={() =>
                        setShowQualifyModal(true)
                    }
                >
                    Kwalificatie
                </button>
                <QualifyResultModal
                    year={getYear(nextRace.sessions.gp.toString())}
                    round={nextRace.round}
                    raceName={nextRace.name}
                    showModal={showQualifyModal}
                    setShowModal={setShowQualifyModal}
                />
            </div>
        );
        }
    }

    if (!nextRace) {
        return <Loading isLoading={true} />;
    }

    return (
        <div className="flex flex-col w-auto lg:flex-row pt-4">
            <div className="grid flex-grow place-items-center w-90 h-fit lg:mr-5 mb-5">
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {nextRace.name}
                </h1>

                <p className="my-5 text-xl leading-8 text-gray-700">
                    {nextRace.circuitName}
                </p>

                <Countdown date={nextRace.sessions.gp} />

                {qualifyingResults(nextRace)}
            </div>
            <div className="divider divider-horizontal h-auto"></div>
            <div className="grid h-auto flex-grow place-items-center">
                <RaceInfo race={nextRace} />
            </div>
        </div>
    );
};
