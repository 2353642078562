import { useEffect, useState } from "react";

interface CountDownModel {
    years: number;
    months: number;
    days: number;
    hours: number;
    min: number;
    sec: number;
    millisec: number;
    countDownReached: boolean;
}

interface CountDownProps {
    date: any
}

export const Countdown = (props: CountDownProps) => {
    const [countDown, setCountDown] = useState({
        years: 0,
        months: 0,
        days: 0,
        hours: 0,
        min: 0,
        sec: 0,
        millisec: 0,
        countDownReached: false,
    });

    useEffect(() => {
        calculateCountdown(props.date);

        const interval = setInterval(() => {
            calculateCountdown(props.date);
        }, 1000);
        return () => clearInterval(interval);
    }, [props.date]);

    function calculateCountdown(endDate: string)  {
        let diff = (Date.parse(endDate) - new Date().getTime()) / 1000;

        const timeLeft = {
            years: 0,
            months: 0,
            days: 0,
            hours: 0,
            min: 0,
            sec: 0,
            millisec: 0,
            countDownReached: true
        };

        // clear countdown when date is reached
        if (diff <= 0) {
            return timeLeft;
        }

        timeLeft.countDownReached = false;

        // calculate time difference between now and expected date
        if (diff >= 365.25 * 86400) {
            // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 365.25 * 86400) {
            // 365.25 * 24 * 60 * 60
            timeLeft.years = Math.floor(diff / (365.25 * 86400));
            diff -= timeLeft.years * 365.25 * 86400;
        }
        if (diff >= 86400) {
            // 24 * 60 * 60
            timeLeft.days = Math.floor(diff / 86400);
            diff -= timeLeft.days * 86400;
        }
        if (diff >= 3600) {
            // 60 * 60
            timeLeft.hours = Math.floor(diff / 3600);
            diff -= timeLeft.hours * 3600;
        }
        if (diff >= 60) {
            timeLeft.min = Math.floor(diff / 60);
            diff -= timeLeft.min * 60;
        }
        timeLeft.sec = Math.round(diff);

        setCountDown(timeLeft);
    }

    return (
        <div className="grid grid-flow-col gap-5 mt-6 text-center auto-cols-max text-gray-900 sm:mb-6">
            <div className="flex flex-col">
                <span className="countdown font-mono text-3xl">
                     {/*
                        // @ts-ignore */}
                    <span style={{"--value": countDown.days}}></span>
                </span>
                days
            </div>
            <div className="flex flex-col">
                <span className="countdown font-mono text-3xl">
                    {/*
                        // @ts-ignore */}
                    <span style={{"--value": countDown.hours}}></span>
                </span>
                hours
            </div>
            <div className="flex flex-col">
                <span className="countdown font-mono text-3xl">
                    {/*
                        // @ts-ignore */}
                    <span style={{"--value": countDown.min}}></span>
                </span>
                min
            </div>
            <div className="flex flex-col">
                <span className="countdown font-mono text-3xl">
                    {/*
                        // @ts-ignore */}
                    <span style={{"--value": countDown.sec}}></span>
                </span>
                sec
            </div>
        </div>
    );
};
