import { useEffect, useState } from "react";
import { RacesTable } from "./RacesTable";
import { Race } from "../../models";

export const Schedule = () => {
    const [racesComingUp, setRacesComingUp] = useState<Array<Race>>([]);
    const [racesAlreadyDone, setRacesAlreadyDone] = useState<Array<Race>>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const date = new Date();
        fetch(`api/race/${date.getFullYear()}`)
            .then((response) => response.json())
            .then((data) => {
                date.setMinutes(0);
                date.setHours(0);

                let racesComingUp = data.filter(
                    (x: Race) => new Date(x.sessions.gp) >= date
                );

                let racesAlreadyDone = data.filter(
                    (x: Race) => new Date(x.sessions.gp) < date
                );

                setRacesComingUp(racesComingUp);
                setRacesAlreadyDone(racesAlreadyDone);
                setLoading(false);
            });
    }, []);

    return (
        <div className="sm:w-3/4 2xl:w-3/5">
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Race schema</h1>
            
            <hr className="border-gray-200 sm:mx-auto border my-4" />

            <h3 className="mt-7 text-lg font-bold tracking-tight text-gray-900 sm:text-xl">Komende races</h3>
            <RacesTable
                races={racesComingUp}
                isLoading={loading}
                isPastRaces={false}
            ></RacesTable>

            <hr className="border-gray-200 sm:mx-auto border my-4" />

            <h3 className="mt-7 text-lg font-bold tracking-tight text-gray-900 sm:text-xl">Afgelopen races</h3>
            <RacesTable
                races={racesAlreadyDone}
                isLoading={loading}
                isPastRaces={true}
            ></RacesTable>
        </div>
    );
};
