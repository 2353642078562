import { RaceResultModal } from "../RaceResults/RaceResultModal";
import { QualifyResultModal } from "../RaceResults/QualifyResultModal";
import { Race } from "../../models";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Loading } from "../Shared/Loading";
import { RaceInfo } from "./RaceInfo";

interface IRacesTableProps {
    isLoading: boolean;
    races: Array<Race>;
    isPastRaces: boolean;
}

interface IModal {
    name: string;
    type: ModalType;
}

enum ModalType {
    RaceResult = 1,
    QualificationResult = 2,
}

export const RacesTable = (props: IRacesTableProps) => {
    const [modal, setModal] = useState<IModal>();
    const [openedRaceRow, setOpenedRaceRow] = useState<string>();

    function formatTimeToText(time: Date) {
        const date = new Date(time);

        const hours = date.getHours();
        const minutes = date.getMinutes();

        const hoursFormatted = ("0" + hours).slice(-2);
        const minutesFormatted = ("0" + minutes).slice(-2);

        return hoursFormatted + ":" + minutesFormatted;
    }

    function formatDateToText(dateString: Date) {
        const monthNames = [
            "januari",
            "februari",
            "maart",
            "april",
            "mei",
            "juni",
            "juli",
            "augustus",
            "september",
            "oktober",
            "november",
            "december",
        ];

        const date = new Date(dateString);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();

        return day + " " + monthNames[month] + " " + year;
    }

    function getYear(dateString: Date) {
        const date = new Date(dateString);
        return date.getFullYear();
    }

    function adjustShowModal(
        clickEvent: any,
        raceName: string,
        modalType: ModalType,
        showModal: boolean
    ) {
        if (clickEvent) {
            clickEvent.stopPropagation();
        }

        if (!showModal) {
            setModal(undefined);
            return;
        }

        setModal({
            name: raceName,
            type: modalType,
        });
    }

    function getResultButtons(
        isPastRace: boolean,
        year: number,
        round: number,
        raceName: string
    ) {
        if (isPastRace) {
            return (
                <td className="pr-0 pb-0">
                    <div className="md:inline-flex flex-row hidden">
                        <button
                            className="btn btn-neutral btn-outline mr-3"
                            onClick={(e) =>
                                adjustShowModal(
                                    e,
                                    raceName,
                                    ModalType.RaceResult,
                                    true
                                )
                            }
                        >
                            Results
                        </button>

                        <button
                            className="btn btn-neutral btn-outline"
                            onClick={(e) =>
                                adjustShowModal(
                                    e,
                                    raceName,
                                    ModalType.QualificationResult,
                                    true
                                )
                            }
                        >
                            Kwalificatie
                        </button>
                    </div>

                    <RaceResultModal
                        year={year}
                        round={round}
                        raceName={raceName}
                        showModal={
                            modal?.name === raceName &&
                            modal?.type === ModalType.RaceResult
                        }
                        setShowModal={(showModal: boolean) =>
                            adjustShowModal(
                                undefined,
                                raceName,
                                ModalType.RaceResult,
                                showModal
                            )
                        }
                    />
                    <QualifyResultModal
                        year={year}
                        round={round}
                        raceName={raceName}
                        showModal={
                            modal?.name === raceName &&
                            modal?.type === ModalType.QualificationResult
                        }
                        setShowModal={(showModal: boolean) =>
                            adjustShowModal(
                                undefined,
                                raceName,
                                ModalType.RaceResult,
                                showModal
                            )
                        }
                    />

                    <div className="dropdown dropdown-bottom dropdown-end md:hidden inline-flex">
                        <label
                            tabIndex={0}
                            onClick={(e) => e.stopPropagation()}
                            className="btn btn-circle btn-outline"
                        >
                            <EllipsisVerticalIcon className="h-7" />
                        </label>
                        <ul
                            tabIndex={0}
                            className="dropdown-content z-[1] menu p-2 shadow bg-gray-100 rounded-box w-52"
                        >
                            <li>
                                <button
                                    onClick={(e) =>
                                        adjustShowModal(
                                            e,
                                            raceName,
                                            ModalType.RaceResult,
                                            true
                                        )
                                    }
                                >
                                    Results
                                </button>
                            </li>
                            <li>
                                <button
                                    onClick={(e) =>
                                        adjustShowModal(
                                            e,
                                            raceName,
                                            ModalType.QualificationResult,
                                            true
                                        )
                                    }
                                >
                                    Kwalificatie
                                </button>
                            </li>
                        </ul>
                    </div>
                </td>
            );
        }
    }

    function changeOpenedRow(raceName: string) {
        if (modal) {
            return;
        }

        if (openedRaceRow === raceName) {
            setOpenedRaceRow(undefined);
            return;
        }

        setOpenedRaceRow(raceName);
    }

    function renderRacesSchedule(races: Array<Race>, isPastRaces: boolean) {
        if (props.isLoading) {
            return <Loading isLoading={props.isLoading} />;
        }

        if (races.length === 0) {
            return <p>Geen races gevonden om te weergeven.</p>;
        }

        return (
            <table className="overflow-x-auto overflow-y-auto table text-gray-900 collapse">
                <thead>
                    <tr className="text-gray-400 border-gray-400">
                        <th>Race naam</th>
                        <th>Datum</th>
                        <th>Tijd</th>
                        {isPastRaces ? (
                            <>
                                <th></th>
                            </>
                        ) : null}
                        <th className="invisible w-0 h-0 m-0 p-0"></th>
                    </tr>
                </thead>
                <tbody>
                    {races.map((race: Race) => {
                        return (
                            <>
                                <tr
                                    key={race.name}
                                    className={`border-gray-400 pb-0 border-b-0 ${
                                        modal ? "" : "cursor-pointer"
                                    }`}
                                    onClick={() => changeOpenedRow(race.name)}
                                >
                                    <td className="pb-0">{race.name}</td>
                                    <td className="pb-0">
                                        {formatDateToText(race.sessions.gp)}
                                    </td>
                                    <td className="pb-0">
                                        {formatTimeToText(race.sessions.gp)}
                                    </td>
                                    {getResultButtons(
                                        isPastRaces,
                                        getYear(race.sessions.gp),
                                        race.round,
                                        race.name
                                    )}
                                    <td className="invisible w-0 h-0 m-0 p-0">
                                        <input
                                            className="invisible w-0 h-0 m-0 p-0"
                                            type="radio"
                                            name="my-accordion-1"
                                        />{" "}
                                    </td>
                                </tr>
                                <tr
                                    key={`${race.name}-arrow`}
                                    className={`border-gray-400 border-t-0 ${
                                        modal ? "" : "cursor-pointer"
                                    }`}
                                    onClick={() => changeOpenedRow(race.name)}
                                >
                                    <td className="m-0 p-0 pb-1 text-gray-500" colSpan={isPastRaces ? 4 : 3}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className={`w-3 h-3 mx-auto ${
                                                openedRaceRow === race.name
                                                    ? "hidden"
                                                    : "block"
                                            }`}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                            />
                                        </svg>

                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                            className={`w-3 h-3 mx-auto ${
                                                openedRaceRow === race.name
                                                    ? "block"
                                                    : "hidden"
                                            }`}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M4.5 15.75l7.5-7.5 7.5 7.5"
                                            />
                                        </svg>
                                    </td>
                                </tr>
                                <tr
                                    key={`${race.name}-details`}
                                    className={`border-gray-400`}
                                >
                                    <td
                                        className={`${
                                            openedRaceRow === race.name
                                                ? "display"
                                                : "hidden"
                                        }`}
                                        colSpan={isPastRaces ? 4 : 3}
                                    >
                                        <RaceInfo race={race} />
                                    </td>
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    return (
        <div className="mt-5">
            {renderRacesSchedule(props.races, props.isPastRaces)}
        </div>
    );
};
