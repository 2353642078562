import { useEffect, useState } from "react";
import { RaceResultAnnotation } from "./RaceResultAnnotation";
import { RaceResult } from "../../models";
import { Loading } from "../Shared/Loading";
import Div100vh from "react-div-100vh";

interface IRaceResultModalProps {
    year: number;
    round: number;
    raceName: string;
    showModal: boolean;
    setShowModal(showModal: boolean): void;
}

export function RaceResultModal({
    year,
    round,
    raceName,
    showModal,
    setShowModal,
}: IRaceResultModalProps) {
    const [raceResult, setRaceResult] = useState<RaceResult>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (showModal && !raceResult) {
            fetch(`api/results/race/${year}/${round}`)
                .then((response) => response.json())
                .then((data) => {
                    setRaceResult(data);
                    setIsLoading(false);
                });
        }
    }, [showModal, year, round, raceResult]);

    function getRaceResultsTable() {
        if (isLoading) {
            return <Loading isLoading={isLoading} />;
        }

        if (!raceResult?.results) {
            return <p>Kwalificatie resultaten zijn nog niet beschikbaar.</p>;
        }

        return (
            <table className="table">
                <thead>
                    <tr className="text-gray-400 border-gray-400">
                        <th className="px-1 py-2">Positie</th>
                        <th className="px-1 py-2">Rijder</th>
                        <th className="px-1 py-2">Start positie</th>
                        <th className="px-1 py-2">Verschil</th>
                        <th className="px-1 py-2">Punten</th>
                    </tr>
                </thead>
                <tbody>
                    {raceResult.results.map((resultRow) => (
                        <tr
                            className="border-gray-400"
                            key={resultRow.position}
                        >
                            <td className="px-1 py-2">{resultRow.position}</td>
                            <td className="px-1 py-2">
                                {resultRow.driver.givenName}{" "}
                                {resultRow.driver.familyName}
                            </td>
                            <td className="px-1 py-2">
                                {resultRow.grid === 0
                                    ? "Pitlane"
                                    : resultRow.grid}
                            </td>
                            <td className="px-1 py-2">
                                <RaceResultAnnotation
                                    startPosition={resultRow.grid}
                                    endPosition={resultRow.position}
                                ></RaceResultAnnotation>
                            </td>
                            <td className="px-1 py-2">{resultRow.points}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    return (
        <dialog
            className="modal backdrop-blur-sm bg-gray-500 bg-opacity-50 md:!h-auto !h-full"
            open={showModal}
        >
            <Div100vh className="w-full !p-3 grid h-screen place-items-center">
                <div className="modal-box max-w-3x overflow-y-auto h-auto max-h-full p-0 pb-2 bg-gray-100 text-gray-900 rounded-md">
                    <div className="w-full m-0 bg-gray-100">
                        <form method="dialog">
                            <button
                                className="btn btn-sm btnd-circle btn-ghost absolute right-2 top-2"
                                onClick={() => setShowModal(false)}
                            >
                                ✕
                            </button>
                        </form>

                        <h3 className="text-lg font-bold px-5 pt-5">
                            {raceName}
                        </h3>
                        <hr className="border-gray-200 sm:mx-auto border my-4" />
                    </div>

                    <div className="overflow-x-auto overflow-y-auto px-5 pb-5">
                        {getRaceResultsTable()}
                    </div>
                </div>
            </Div100vh>
        </dialog>
    );
}
