interface ILoadingProps {
    isLoading: boolean;
}

export function Loading({ isLoading: loading }: ILoadingProps) {
    return (
        <p className={loading ? "visible" : "invisible"}>
            <span className="loading loading-spinner loading-sm"></span> Laden...
        </p>
    )
}