export default function Footer() {
    return (
        <footer className="bg-gray-100">
            <hr className="my-0 border-gray-200 sm:mx-auto border" />
            <div className="w-full mx-auto max-w-screen-xl p-4 text-center">
                <span className="text-sm font-semibold leading-6 text-gray-600">
                    Proudly hosted by{" "}
                    <a href="https://argo-ict.com/" target="_blank" className="hover:underline" rel="noreferrer">
                        ARGO-ICT.COM
                    </a>
                </span>
            </div>
        </footer>
    );
}