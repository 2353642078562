import { Route } from "react-router";
import Layout from "./layout/Layout";
import { Home } from "./components/Home";
import { Schedule } from "./components/RaceSchema/Schedule";
import { Standings } from "./components/Ranking/Standings";
import { SeasonRaces } from "./components/RaceResults/SeasonRaces";
import { Routes } from "react-router-dom";

const App = () => {
  return (
    <Layout>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/history" element={<SeasonRaces />} />
        <Route path="/standings" element={<Standings />} />
      </Routes>
    </Layout>
  );
};

export default App;
