interface IYearPickerProps {
    startYear?: number;
    lowestYear?: number;
    onChange: (n: number) => any;
}

export const YearPicker = (props: IYearPickerProps) => {
    function yearsToRender() {
        const year = props.startYear
            ? props.startYear
            : new Date().getFullYear();
        const lowestYear = props.lowestYear ? props.lowestYear : year - 10;

        let yearArray = [];
        for (let i = 0; i < year - lowestYear + 1; i++) {
            yearArray[i] = year - i;
        }

        return yearArray;
    }

    return (
        <>
            <div className="form-control w-full max-w-xs">
                <label className="label">
                    <span className="label-text text-gray-900">
                        Jaar
                    </span>
                </label>
                <select className="select select-bordered bg-gray-200 text-gray-900 w-full max-w-xs"
                    onChange={(e) => props.onChange(parseInt(e.target.value))}>
                    {yearsToRender().map((year) => (
                        <option
                            key={year}
                            value={year}
                            defaultValue={year}
                        >
                            {year}
                        </option>
                    ))}
                </select>
            </div>
        </>
        // <Form>
        //     <FormGroup>
        //         <Label for="yearSelection">Jaar</Label>
        //         <Input
        //             type="select"
        //             name="yearSelection"
        //             id="yearSelection"
        //             onChange={(e) => props.onChange(parseInt(e.target.value))}
        //             defaultValue={new Date().getFullYear()}
        //         >
        // {yearsToRender().map((year) => (
        //     <option key={year} value={year}>
        //         {year}
        //     </option>
        // ))}
        //         </Input>
        //     </FormGroup>
        // </Form>
    );
};
