import { FaChevronUp, FaChevronDown, FaMinus } from "react-icons/fa";

import "./RaceResultAnnotation.css";

interface IRaceResultAnnotationProps {
    startPosition: number;
    endPosition: number;
}

export const RaceResultAnnotation = (props: IRaceResultAnnotationProps) => {
    const startPosition =
        props.startPosition === 0 ? 20 : props.startPosition;
    const endPosition = props.endPosition;

    if (startPosition > endPosition) {
        const difference = startPosition - endPosition;

        return (
            <>
                <span className="icon green">
                    <FaChevronUp className="inline"></FaChevronUp>
                </span>
                <span>{difference}</span>
            </>
        );
    } else if (startPosition < endPosition) {
        const difference = endPosition - startPosition;

        return (
            <>
                <span className="icon red">
                    <FaChevronDown className="inline"></FaChevronDown>
                </span>
                <span>{difference}</span>
            </>
        );
    } else {
        return (
            <>
                <span className="icon">
                    <FaMinus className="inline"></FaMinus>
                </span>
            </>
        );
    }
};
