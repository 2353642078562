import { useEffect, useState } from "react";
import { ConstructorStandingsList } from "../../models/ConstructorStandingsList";
import { Loading } from "../Shared/Loading";

interface IConstructorStandingsProps {
    year: number;
}

export function ConstructorStandings({ year }: IConstructorStandingsProps) {
    const [constructorStandingsList, setConstructorStandingsList] =
        useState<ConstructorStandingsList>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        setLoading(true);

        fetch(`api/standing/${year}/constructor`)
            .then((response) => response.json())
            .then((data) => {
                setConstructorStandingsList(data);
                setLoading(false);
            });
    }, [year]);

    function renderStandings() {
        if (!constructorStandingsList) {
            return;
        }

        if (constructorStandingsList.constructorStandings.length === 0) {
            return <p>Geen rankings gevonden om te weergeven.</p>;
        }

        return (
            <div className="accordion" id="accordion">
                <table className="table text-gray-900">
                    <thead>
                        <tr className="text-gray-400 border-gray-400">
                            <th>Positie</th>
                            <th>Constructeur</th>
                            <th>Winst</th>
                            <th>Punten</th>
                        </tr>
                    </thead>
                    <tbody>
                        {constructorStandingsList.constructorStandings.map(
                            (standingRow) => (
                                <tr
                                    key={standingRow.position}
                                    className="border-gray-400"
                                >
                                    <td>{standingRow.position}</td>
                                    <td>{standingRow.constructor.name}</td>
                                    <td>{standingRow.wins}</td>
                                    <td>{standingRow.points}</td>
                                </tr>
                            )
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <>
            <h3 className="my-4 text-xl font-bold tracking-tight text-gray-900 sm:text-xl">
                Constructeurs
            </h3>

            <Loading isLoading={loading} />

            {renderStandings()}
        </>
    );
}
