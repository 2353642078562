import { useEffect, useState } from "react";
import { QualifyResult } from "../../models";
import { Loading } from "../Shared/Loading";
import Div100vh from "react-div-100vh";

interface IQualifyResultProps {
    year: number;
    round: number;
    raceName: string;
    showModal: boolean;
    setShowModal(showModal: boolean): void;
}

export function QualifyResultModal({
    year,
    round,
    raceName,
    showModal,
    setShowModal,
}: IQualifyResultProps) {
    const [qualifyResult, setQualifyResult] = useState<QualifyResult>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (showModal && !qualifyResult) {
            fetch(`api/results/race/qualify/${year}/${round}`)
                .then((response) => response.json())
                .then((data) => {
                    setQualifyResult(data);
                    setIsLoading(false);
                });
        }
    }, [showModal, year, round, qualifyResult]);

    function getQualifyResultsTable() {
        if (isLoading) {
            return <Loading isLoading={isLoading} />;
        }

        if (!qualifyResult?.qualifyingResults) {
            return <p>Kwalificatie resultaten zijn nog niet beschikbaar.</p>;
        }

        return (
            <table className="table">
                <thead>
                    <tr className="text-gray-400 border-gray-400">
                        <th className="px-1 py-2">Positie</th>
                        <th className="px-1 py-2">Rijder</th>
                        <th className="px-1 py-2">Q1</th>
                        <th className="px-1 py-2">Q2</th>
                        <th className="px-1 py-2">Q3</th>
                    </tr>
                </thead>
                <tbody>
                    {qualifyResult.qualifyingResults.map((resultRow) => (
                        <tr
                            className="border-gray-400"
                            key={resultRow.position}
                        >
                            <td className="px-1 py-2">{resultRow.position}</td>
                            <td className="px-1 py-2">
                                {resultRow.driver.givenName}{" "}
                                {resultRow.driver.familyName}
                            </td>
                            <td className="px-1 py-2">{resultRow.q1}</td>
                            <td className="px-1 py-2">{resultRow.q2}</td>
                            <td className="px-1 py-2">{resultRow.q3}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }

    return (
        <dialog
            className="modal backdrop-blur-sm bg-gray-500 bg-opacity-50 md:!h-auto !h-full"
            open={showModal}
        >
            <Div100vh className="w-full !p-3 grid h-screen place-items-center">
                <div className="modal-box max-w-3x overflow-y-auto h-auto max-h-full p-0 pb-2 bg-gray-100 text-gray-900 rounded-md">
                    <div className="w-full m-0 bg-gray-100">
                        <form method="dialog">
                            <button
                                className="btn btn-sm btnd-circle btn-ghost absolute right-2 top-2"
                                onClick={() => setShowModal(false)}
                            >
                                ✕
                            </button>
                        </form>

                        <h3 className="text-lg font-bold px-5 pt-5">
                            {raceName}
                        </h3>
                        <hr className="border-gray-200 sm:mx-auto border my-4" />
                    </div>

                    <div className="overflow-x-auto overflow-y-auto px-5 pb-5">
                        {getQualifyResultsTable()}
                    </div>
                </div>
            </Div100vh>
        </dialog>
    );
}
