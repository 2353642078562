import { useEffect, useState } from "react";
import { YearPicker } from "../Shared/YearPicker";
import { RacesTable } from "../RaceSchema/RacesTable";
import { Race } from "../../models";

export const SeasonRaces = () => {
    const [races, setRaces] = useState<Array<Race>>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [previousYear] = useState<number>(new Date().getFullYear() - 1);

    useEffect(() => {
        loadStandings(previousYear);
    }, []);

    function loadStandings(year: number) {
        fetch(`api/race/${year}`)
            .then((response) => response.json())
            .then((data) => {
                setRaces(data);
                setLoading(false);
            });
    }

    return (
        <div className="sm:w-3/4 2xl:w-3/5">
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Historische uitslagen</h1>
            
            <hr className="border-gray-200 sm:mx-auto border my-4" />
            
            <YearPicker
                lowestYear={2018}
                startYear={previousYear}
                onChange={loadStandings}
            ></YearPicker>
            
            <RacesTable
                races={races}
                isLoading={loading}
                isPastRaces={true}
            ></RacesTable>
        </div>
    );
};
