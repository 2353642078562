import React from "react";
import "./Layout.css";
import NavMenu from "./NavMenu";
import Footer from "./Footer";

type LayoutProps = {
    children: React.ReactNode;
};

const Layout = (props: LayoutProps) => {
    return (
        <div className="bg-gray-100 h-full justify-between flex flex-col">
            <NavMenu />

            <main className="flex-grow mb-auto relative isolate px-6 lg:px-8">
                <div className="mb-10 flex items-center justify-center gap-x-8">
                    {props.children}
                </div>
            </main>

            <Footer />
        </div>
    );
};

export default Layout;
