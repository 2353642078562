import { DateTimeBlock } from "../Shared/DateTimeBlock";
import { Race } from "../../models";

interface IRaceInfoProps {
    race: Race;
}

export const RaceInfo = (props: IRaceInfoProps) => {
    function getRaceInfo() {
        if (
            props.race.sessions.sprintQualifying &&
            props.race.sessions.sprintQualifying !==
                new Date("0001-01-01T00:00:00Z")
        ) {
            return (
                <>
                    <DateTimeBlock
                        title="Eerste vrije training"
                        dateTime={props.race.sessions.fp1}
                    />
                    <DateTimeBlock
                        title="Sprint Kwalificatie"
                        dateTime={props.race.sessions.sprintQualifying}
                    />
                    <DateTimeBlock
                        title="Sprint"
                        dateTime={props.race.sessions.sprint}
                    />
                    <DateTimeBlock
                        title="Kwalificatie"
                        dateTime={props.race.sessions.qualifying}
                    />
                    <DateTimeBlock title="Race" dateTime={props.race.sessions.gp} />
                </>
            );
        } else {
            return (
                <>
                    <DateTimeBlock
                        title="Eerste vrije training"
                        dateTime={props.race.sessions.fp1}
                    />
                    <DateTimeBlock
                        title="Tweede vrije training"
                        dateTime={props.race.sessions.fp2}
                    />
                    <DateTimeBlock
                        title="Derde vrije training"
                        dateTime={props.race.sessions.fp3}
                    />
                    <DateTimeBlock
                        title="Kwalificatie"
                        dateTime={props.race.sessions.qualifying}
                    />
                    <DateTimeBlock title="Race" dateTime={props.race.sessions.gp} />
                </>
            );
        }
    }
    

    return (
        <div className="flex flex-col w-full items-center gap-4">
            {getRaceInfo()}
        </div>
    );
};
