interface IDateTimeBlockProps {
    title: string;
    dateTime: Date;
}

export const DateTimeBlock = (props: IDateTimeBlockProps) => {
    function formatDateToDateTimeText(dateString: Date) {
        const monthNames = [
            "januari",
            "februari",
            "maart",
            "april",
            "mei",
            "juni",
            "juli",
            "augustus",
            "september",
            "oktober",
            "november",
            "december",
        ];

        const date = new Date(dateString);

        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const hoursFormatted = ("0" + hours).slice(-2);
        const minutesFormatted = ("0" + minutes).slice(-2);

        return `${day} ${monthNames[month]} ${year}, ${hoursFormatted}:${minutesFormatted}`;
    }

    return (
        <div className="h-auto card max-w-80 w-80 mb-3 rounded-md shadow bg-white border-gray-400 border place-items-center p-4">
            <h3 className="mb-0 text-xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                {props.title}
            </h3>
            <p className="mt-4 text-m leading-8 text-gray-700">
                {formatDateToDateTimeText(props.dateTime)}
            </p>
        </div>
    );
};
